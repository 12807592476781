import WeblinkHeader from "../components/WebLinkHeader"
import { useEffect, useState } from "react"
import { useLogto } from '@logto/react';
const { domain } = require('../config/generalConfig.json')

function FirmwareStats() {
    const { signIn, getAccessToken, isAuthenticated, isLoading } = useLogto()
    const [isReady, setIsReady] = useState(false) // Must be changed false later
    const [accessToken, setAccessToken] = useState(null)
    const [selectedFirmOption, setSelectedFirmOption] = useState(null)
    const [firmversionList, setFirmversionList] = useState(null)
    const [existingFirmwareList, setExistingFirmwareList] = useState(null)
    const [firmVersion, setFirmVersion] = useState(null)
    const [firmStatsArr, setFirmStatsArr] = useState([null, null, null, null, null, null, null, null, null])
    const [firmFile, setFirmFile] = useState(0)
    const [isLogSent, setIsLogSent] = useState(false)

    useEffect(() => {
        async function getAPIToken() {
            if (!isLoading && isAuthenticated && !accessToken) {
                const actk = await getAccessToken('https://api.cendori.entr.netfrc.com/')
                setAccessToken(actk)
            }
            console.log(accessToken)
        }

        getAPIToken()
        if (accessToken && !existingFirmwareList) fetchFirmwareList()
        if (accessToken && !isLogSent) {
            sendLogInfo("펌웨어 통계 페이지")
            setIsLogSent(true)
        }
    }, [accessToken, isAuthenticated, isLoading])

    async function sendLogInfo(text) {
        if (accessToken) {
            const formData = new FormData()
            formData.append("api", text)
            fetch('https://api.cendori.entr.netfrc.com/logs/view', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                body: formData
            }).then((response) => response.json())
                .then((data) => {
                    console.log(data)
                })
        }
    }

    async function fetchFirmwareList() {
        fetch('https://api.cendori.entr.netfrc.com/firmware/list', {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((response) => response.json())
            .then((data) => {
                if (data.error) alert(data.error)
                else {
                    if (!data.authorized) {
                        alert("접근 권한 없음")
                        return
                    }
                    console.log(data.data)
                    var dataChange = []
                    dataChange[0] = {}
                    dataChange[0].id = data.data[0].id
                    dataChange[0].name = data.data[0].name
                    for (var i = 0; i < data.data.length; i++) {
                        var isExist = false
                        for (var j = 0; j < dataChange.length; j++) {
                            if (data.data[i].id === dataChange[j].id) {
                                isExist = true
                                break
                            }
                        }
                        if (!isExist) {
                            let len = dataChange.length
                            dataChange[len] = {}
                            dataChange[len].id = data.data[i].id
                            dataChange[len].name = data.data[i].name
                        }
                    }
                    setExistingFirmwareList(dataChange)
                    setSelectedFirmOption(dataChange[0].id)
                    setVersionList(data.data)
                }
            }).catch((error) => console.log(error))
    }

    function setVersionList(firmwareList) {
        var dataChange = {}
        for (var i = 0; i < firmwareList.length; i++) {
            if (!Object.keys(dataChange).includes(firmwareList[i].id)) {
                dataChange[firmwareList[i].id] = {}
                dataChange[firmwareList[i].id][firmwareList[i].version] = firmwareList[i].firmware
            }
            else {
                dataChange[firmwareList[i].id][firmwareList[i].version] = firmwareList[i].firmware
            }
        }
        setFirmversionList(dataChange)
        setFirmVersion(Object.keys(dataChange[firmwareList[0].id])[0])
        console.log(dataChange)
    }

    function submitForm(e) {
        e.preventDefault()
        for (var i = 0; i < existingFirmwareList.length; i++) {
            if (existingFirmwareList[i].id === selectedFirmOption) {
                setFirmStatsArr([existingFirmwareList[i].name, selectedFirmOption, firmversionList[selectedFirmOption][firmVersion][firmFile].fname, firmVersion, null, firmversionList[selectedFirmOption][firmVersion][firmFile].comment, firmversionList[selectedFirmOption][firmVersion][firmFile].uploadtime, firmversionList[selectedFirmOption][firmVersion][firmFile].uploader, firmversionList[selectedFirmOption][firmVersion][firmFile].requests])
                break
            }
        }
        setIsReady(true)
    }

    return (
        <div>
            {
                !isLoading ? (
                    isAuthenticated ? (
                        <div className="cendoriwebbody" style={{ minHeight: "100vh" }}>
                            <WeblinkHeader />
                            <div className="cendorifirmwarestatsdiv">
                                <form id="firmware-stats-form" name="firmware-stats-form" className="form-2" onSubmit={submitForm}>
                                    <label for="field-4" className="field-label">펌웨어 기종 </label>
                                    <select id="ProfileID-3" name="ProfileID-3" data-name="Profile ID 3" required="" value={selectedFirmOption} onChange={(e) => {
                                        setSelectedFirmOption(e.target.value)
                                    }} className="select-field nopadding w-select">
                                        {
                                            existingFirmwareList ? (
                                                existingFirmwareList.map((option) => (
                                                    <option value={option.id} key={option.id}>{option.name + " (" + option.id + ")"}</option>
                                                ))
                                            ) : (
                                                <option></option>
                                            )
                                        }
                                    </select>
                                    <label for="firmwareVersion" className="field-label">펌웨어 버젼</label>
                                    <select id="firmwareVersion" name="firmwareVersion" required="" value={firmVersion} onChange={(e) => {
                                        setFirmVersion(e.target.value)
                                    }} className="select-field nopadding w-select">
                                        {
                                            (firmversionList && selectedFirmOption) ? (
                                                Object.keys(firmversionList[selectedFirmOption]).map((option, index) => {
                                                    return <option key={option} value={option}>{option}</option>
                                                })
                                            ) : (
                                                <option></option>
                                            )
                                        }
                                    </select>
                                    <label for="firmwareFiles" className="field-label">파일 이름</label>
                                    <select id="firmwareFiles" name="firmwareFiles" required="" value={firmFile} onChange={(e) => {
                                        setFirmFile(e.target.value)
                                    }} className="select-field nopadding end w-select">
                                        {
                                            (firmversionList && selectedFirmOption && firmVersion) ? (
                                                firmversionList[selectedFirmOption][firmVersion].map((option, index) => {
                                                    return <option key={index} value={index}>{option.fname}</option>
                                                })
                                            ) : (
                                                <option></option>
                                            )
                                        }
                                    </select>
                                    <input type="submit" className="submit-button w-button" value="통계 확인" />
                                </form>
                                {
                                    isReady ?
                                        <div className="cendoristatisticsdiv">
                                            <div className="cendorimaindivlogintext firmwareinfo">펌웨어 통계</div>
                                            <div className="cendorifirmwarestatisticsdiv">
                                                <div className="cendorisecondarytext">기종명</div>
                                                <div className="cendorisecondarytextdiv">{
                                                    firmStatsArr[0] ? (
                                                        <div>{firmStatsArr[0]}</div>
                                                    ) : (
                                                        <div>기록없음</div>
                                                    )
                                                }</div>
                                                <div className="cendorisecondarytext">SmartThings ID</div>
                                                <div className="cendorisecondarytextdiv">{
                                                    firmStatsArr[1] ? (
                                                        <div>{firmStatsArr[1]}</div>
                                                    ) : (
                                                        <div>기록없음</div>
                                                    )
                                                }</div>
                                                <div className="cendorisecondarytext">펌웨어 파일 이름</div>
                                                <div className="cendorisecondarytextdiv">{
                                                    firmStatsArr[2] ? (
                                                        <div>{firmStatsArr[2]} (URL: <a href={"https://fw.ota.client.netfrc.com/" + firmStatsArr[1] + "/" + firmStatsArr[3] + "/" + firmStatsArr[2]} className="cendorigraylink">{"https://fw.ota.client.netfrc.com/" + firmStatsArr[1] + "/" + firmStatsArr[3] + "/" + firmStatsArr[2]}</a>)</div>
                                                    ) : (
                                                        <div>기록없음</div>
                                                    )
                                                }
                                                </div>
                                                <div className="cendorisecondarytext">펌웨어 버젼</div>
                                                <div className="cendorisecondarytextdiv">{
                                                    firmStatsArr[3] ? (
                                                        <div>{firmStatsArr[3]}</div>
                                                    ) : (
                                                        <div>기록없음</div>
                                                    )
                                                }</div>
                                                <div className="cendorisecondarytext">업데이트 코멘트 </div>
                                                <div className="cendorisecondarytextdiv">{
                                                    firmStatsArr[5] ? (
                                                        <div>{firmStatsArr[5]}</div>
                                                    ) : (
                                                        <div>기록없음</div>
                                                    )
                                                }</div>
                                                <div className="cendorisecondarytext">업로드 시간 </div>
                                                <div className="cendorisecondarytextdiv">{
                                                    firmStatsArr[6] ? (
                                                        <div>{firmStatsArr[6]}</div>
                                                    ) : (
                                                        <div>기록없음</div>
                                                    )
                                                }</div>
                                                <div className="cendorisecondarytext">업로드 멤버 </div>
                                                <div className="cendorisecondarytextdiv">{
                                                    firmStatsArr[7] ? (
                                                        <div>{firmStatsArr[7]}</div>
                                                    ) : (
                                                        <div>기록없음</div>
                                                    )
                                                }</div>
                                                <div className="cendorisecondarytext">접근 횟수</div>
                                                <div className="cendorisecondarytextdiv">{
                                                    (firmStatsArr[8] || firmStatsArr[8] == 0) ? (
                                                        <div>{firmStatsArr[8]}</div>
                                                    ) : (
                                                        <div>기록없음</div>
                                                    )
                                                }</div>
                                            </div>
                                        </div> :
                                        <div></div>
                                }
                            </div>
                        </div>
                    ) : (
                        signIn(`${domain}/callback`)
                    )
                ) : (
                    <div></div>
                )
            }
        </div>
    )
}

export default FirmwareStats